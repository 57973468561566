<template>
  <div class="jiading">
    <div class="sub_page">
      <h5 class="sub_tit">关于集散地</h5>
      <p>About Us</p>
    </div>
    <div class="video_text">
      <video-player
        class="video-player vjs-custom-skin vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
      ></video-player>
    </div>
    <div class="about_cont">
      <div class="contx-box">
        <p v-text="contText.content"></p>
        <ul class="list">
          <li v-for="(item,index) in contText.contList" :key="index">{{item}}</li>
        </ul>
        <div class="img">
          <img src="../../assets/imgs/jiading/about_001.png" />
          <img src="../../assets/imgs/jiading/about_002.png" />
          <img src="../../assets/imgs/jiading/001.png" />
        </div>
      </div>
      <div class="layout container font_size information">
        <label>科创集散地楼层基本信息</label>
        <ul class="nav">
          <li
            v-for="(navItem,index) in navArr"
            :key="index"
            @click="navBarIndex=index"
            :class="navBarIndex==index?'active':''"
          >
            {{navItem}}
            <div class="triangle_border_up" v-show="navBarIndex==index"></div>
          </li>
        </ul>
        <div class="item_content">
          <div class="item" :class="navBarIndex==0?'itemActive':''" @click="funDom">
            <ul class="img_one_nav">
              <li
                class
                v-for="(item,index) in 5"
                :key="index"
                :class="nav1Index==index?'imgActive':''"
                @click.stop="nav1Index=index"
              ></li>
            </ul>
            <ul class="img_one_c item_img" style="position:relative">
              <li
                style="display: none; top: 77px; left: 253.5px;"
                :style="{display:nav1Index==index?'block':'none'}"
                v-for="(itemSrc,index) in navIcon"
                :key="index"
              >
                <img :src="itemSrc" style="height: 446px; width: 693px;" />
              </li>
            </ul>
          </div>
          <div class="item" :class="navBarIndex==1?'itemActive':''" @click="funDom">
            <ul class="img_two_nav">
              <li
                v-for="(item,index) in 9"
                :key="index"
                :class="nav1Index==index?'imgActive':''"
                @click.stop="nav1Index=index"
              ></li>
            </ul>
            <ul class="img_two_c item_img">
              <li
                v-for="(item,index) in nav2Icon"
                :key="index"
                :style="{display:nav1Index==index?'block':'none'}"
                :class="nav1Index==index?'nav2ContActive':''"
              >
                <img :src="item" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <section class="subject">
      <label>学科主题教室简介</label>
      <div class="wrapper" id="charcontain" style="height: 614.51px;">
        <div
          class="char allClar"
          v-for="(w_item,index) in wrapperObj"
          :key="index"
          :style="{width:w_index==index?'36%':w_index==null?'':'16%',transition:'all 0.6s'}"
          @mouseover="w_index=index"
          @mouseout="w_index=null"
        >
          <div class="title" :style="{color:w_item.styleColor}">{{w_item.tit}}</div>
          <span class="allImgWidth"></span>
          <p>{{w_item.text}}</p>
        </div>
      </div>
    </section>
    <div class="layout container font_size tutor">
      <label>科创集散地课程导师队伍力量</label>
      <p>青少年科创集散地成立专家资源库、 SEED（种子）理事会，由科学家、企业家、工程师和设计师等来自各行业的专家组成。其中，有来自上海科学家科普演讲团的首批20多名涉及物理学、化学、天文学、生物学、医学等自然科学以及新技术等学科领域的院士博士专家，有来自蔚来汽车、联影医疗、深圳优必选科技等优秀企业的企业家、工程师和设计师等专家，他们走进课堂，为孩子们提供更多可能性，帮助他们更好地面向未来创新社会的挑战。</p>
      <div class="img" style="text-align: center;">
        <img src="../../assets/imgs/jiading/006.png" />
      </div>
    </div>
    <div class="layout container font_size vision">
      <label>嘉定青少年科创集散地品质课程愿景</label>
      <p>嘉定青少年科创集散地希望能培养出一批愿意去探索世界、探索真理、心怀敬畏感的孩子，希望能在科创的世界中帮助孩子们建构他们的世界观、人生观、道德感、社会责任感；并希望这些孩子不仅仅是停留在理念或是文字研究报告上，能知行合一，用自己的双手勇敢的去实践认知、去探索追寻，构建起自己的科创精神、世界观。</p>
      <div class="img">
        <img src="../../assets/imgs/jiading/12.png" />
        <img src="../../assets/imgs/jiading/13.png" />
        <img src="../../assets/imgs/jiading/14.png" />
      </div>
      <div class="img">
        <img src="../../assets/imgs/jiading/15.png" />
        <img src="../../assets/imgs/jiading/16.png" />
        <img src="../../assets/imgs/jiading/17.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      w_index: null,
      dom: "",
      navBarIndex: 0,
      navArr: [" 1F", "2.3.4F"],
      nav1Index: null,
      contText: {
        content:
          "嘉定区青少年科创集散地位于嘉定区清河路151号，一期总建筑面积3400㎡，是华东地区第一家专门以青少年科创教育为主题的开放式“科技空间站”。嘉定青少年科创集散地主要以参与性、互动性、体验式教育实践活动为主导，以培养青少年创新思维、实践能力、坚强意志为核心，引领分散在各校、各教育机构的科创教育资源，盘活区内相关企业、院校、专家资源，打造具有嘉定特色的科创教育影响力。",
        contList: [
          "牵引力 （合理利用资源稀缺性）",
          "驱动力 （以集散地为枢纽，整合校外科创资源）",
          "影响力 （通过高度、亮点，形成社会聚焦）",
        ],
      },
      navIcon: [
        require("../../assets/imgs/jiading/about_1_xiuxi.png"),
        require("../../assets/imgs/jiading/about_1_xiuxi.png"),
        require("../../assets/imgs/jiading/about_1_yanjiang.png"),
        require("../../assets/imgs/jiading/2-2.png"),
        require("../../assets/imgs/jiading/002.png"),
      ],
      nav2Icon: [
        require("../../assets/imgs/jiading/about_1_xiuxi.png"),
        require("../../assets/imgs/jiading/about_1_xiuxi.png"),
        require("../../assets/imgs/jiading/about_2_chuangxinchuangye.png"),
        require("../../assets/imgs/jiading/about_2_shuziyishu.png"),
        require("../../assets/imgs/jiading/about_2_chuangwanzhizao.png"),
        require("../../assets/imgs/jiading/about_2_chuangwanbiancheng.png"),
        require("../../assets/imgs/jiading/about_2_kexueshijian.png"),
        require("../../assets/imgs/jiading/about_2_shuziyishu.png"),
        require("../../assets/imgs/jiading/about_2_kexueshijian.png"),
      ],
      wrapperObj: [
        {
          tit: "数字艺术主题教室",
          text:
            "微电影课程拍摄的基地，在这里诞生了很多天才的创意和精彩的剧情。这里有深圳的滑轨微单和苹果电脑，还有windows电脑，可以满足同学们的各种拍摄需要，以及学习需要。",
          styleColor: "#20aee5",
        },
        {
          tit: "创新创业主题教室",
          text:
            "主要用来做学生演讲、体验，增加学生的实景感受；同时满足高中YEA！课程的开设。",
          styleColor: "#e64e4e",
        },
        {
          tit: "创玩智造主题教室",
          text:
            "在普通的教室内飞行器飞行会存在安全防护措施不够，飞行赛道场地无法满足既上课又飞行的条件，本教室就解决了这一问题，专门用于满足学生进行室内无人机竞速飞行体验。",
          styleColor: " #70b8ff",
        },
        {
          tit: "创玩编程主题教室",
          text:
            "慧生活或超级工程师课程的学生在项目主题竞赛结束后，会利用这间教室做项目作品路演展示，展示自己的积木外观以及项目功能，对于优秀的项目作品，我们这间教室提供了很多储物柜，将对这些时优秀项目作品做永久收藏。",
          styleColor: "#fd9039",
        },
        {
          tit: "科学实践主题教室",
          text:
            "主要用于科学创作课程阶段性学生作品集中展示和演讲，因此，本教室的桌面较宽且摆放呈U型给展示作品的学员充分的作品摆放和展示空间。另外，本教室还将作为科学课程优秀作品展的场地用于定期将学生的创新性作品集中展示，给学生、家长和老师体验和感受学员使用科学改善生活创造未来的独特创新尝试。",
          styleColor: "#ba3cc9",
        },
      ],
      playerOptions: {}, // video.js 配置,
    };
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    // video.js 配置
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.playerOptions = {
      // video.js 配置
      muted: true,
      autoplay: true,
      language: 'zh-CN',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      fluid:true,
      sources: [
        {
          type: "video/mp4",
          src: this.QiNiuPath + "/%E5%98%89%E5%AE%9A%E9%9B%86%E6%95%A3%E5%9C%B0_1028_2.mp4",
        },
      ],
      width: "1200px",
      height: "375px",
      
    };
  },
  methods: {
    funDom(event) {
      this.nav1Index = null;
    },
    onPlayerPlay(player) {
      this.openIcon = false;
    },
    onPlayerPause(player) {
      this.openIcon = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/mixin.scss";
.jiading {
  padding-bottom: 88px;
  min-width: 1200px;
}
// 头部标题
.sub_page {
  background: #f4f4f4;
  text-align: center;
  padding: 13px 0;
  background: url("../../assets/imgs/jiading/sub_top_bg.png") top center
    no-repeat;
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(
        src='../../assets/imgs/jiading/sub_top_bg.png',
        sizingMethod='scale');
}
.sub_tit {
  color: #06a7e5;
  position: relative;
  line-height: 28px;
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 2px;
}
.sub_page > p {
  font-size: 19px;
  font-family: tunga;
  font-weight: normal;
  color: rgba(0, 166, 228, 1);
  letter-spacing: 4px;
  padding-top: 6px;
  line-height: 19px;
}
.video_text{
  width: 1200px;
  margin: 30px auto 0 auto;
 }
.about_cont {
  width: 100%;
}
.contx-box {
  width: 1200px;
  margin: 30px auto 0 auto;
}
.contx-box > p {
  text-indent: 2em;
  font-size: 18px;
  color: #5a5a5a;
  line-height: 30px;
  color: #5a5a5a;
}
.list {
  margin: 30px 0 30px 20px;
}
.list li {
  position: relative;
  font-size: 16px;
  margin-bottom: 5px;
  color: #5a5a5a;
}
.list li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #5a5a5a;
  border-radius: 50%;
  margin-right: 10px;
}
.img img {
  margin-right: 70px;
}
.img img:last-child {
  margin-right: 0;
}

.information {
  margin: 50px auto;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.active .triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ff7f00;
  margin: 1px auto 0px auto;
  position: relative;
}
.information label {
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  font-size: 30px;
  text-align: center;
  color: #000;
}
.information .nav {
  width: 100%;
  display: block;
  height: 45px;
  text-align: center;
  margin-bottom: 15px;
}

.nav li {
  float: left;
  border-bottom: 2px solid #c3c3c3;
  width: 100px;
  height: 45px;
  font-size: 18px;
  cursor: pointer;
  color: #666;
  font-size: 18px;
}
.nav .active {
  border-bottom: 2px solid #ff7f00;
}
.information .img_one_nav li:nth-child(1) {
  position: absolute;
  top: 178px;
  left: 540px;
  height: 140px;
  width: 165px;
  cursor: pointer;
}
.information .img_one_nav li:nth-child(2) {
  position: absolute;
  top: 178px;
  left: 397px;
  height: 142px;
  width: 136px;
  cursor: pointer;
}
.information .img_one_nav li:nth-child(3) {
  position: absolute;
  top: 138px;
  left: 710px;
  height: 180px;
  width: 124px;
  cursor: pointer;
}
.information .img_one_nav li:nth-child(4) {
  position: absolute;
  top: 323px;
  left: 397px;
  height: 146px;
  width: 159px;
  cursor: pointer;
}
.information .img_one_nav li:nth-child(5) {
  position: absolute;
  top: 363px;
  left: 562px;
  height: 103px;
  width: 142px;
  cursor: pointer;
}
.information .img_one_nav .imgActive,
.information .img_two_nav .imgActive {
  background: #fec79c;
  opacity: 0.5;
}
li {
  list-style-type: none;
}
/* .item_content .item{
    background: url('../../assets/imgs/jiading/about_1F.png')top center no-repeat;
} */
.itemActive {
  display: block !important;
}
.information .item_content .item {
  display: none;
  height: 600px;
  position: relative;
}
.information .item_img li {
  position: absolute;
}
.information .item_content .item:nth-child(1) {
  background: url("../../assets/imgs/jiading/about_1F.png") no-repeat center
    100%;
}
.information .item_content .item:nth-child(2) {
  background: url("../../assets/imgs/jiading/about_2F.png") no-repeat center
    100%;
}
.information .img_two_nav li:nth-child(1) {
  position: absolute;
  top: 69px;
  left: 75px;
  height: 128px;
  width: 72px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(2) {
  position: absolute;
  top: 69px;
  left: 148px;
  height: 87px;
  width: 77px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(3) {
  position: absolute;
  top: 30px;
  left: 233px;
  height: 125px;
  width: 115px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(4) {
  position: absolute;
  top: 30px;
  left: 355px;
  height: 125px;
  width: 176px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(5) {
  position: absolute;
  top: 30px;
  left: 533px;
  height: 125px;
  width: 181px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(6) {
  position: absolute;
  top: 30px;
  left: 717px;
  height: 125px;
  width: 181px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(7) {
  position: absolute;
  top: 30px;
  left: 900px;
  height: 125px;
  width: 181px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(8) {
  position: absolute;
  top: 461px;
  left: 52px;
  height: 135px;
  width: 169px;
  cursor: pointer;
}
.information .img_two_nav li:nth-child(9) {
  position: absolute;
  top: 461px;
  left: 228px;
  height: 135px;
  width: 169px;
  cursor: pointer;
}
.information .item_img li {
  position: absolute;
}
.nav2ContActive {
  display: block;
  top: 77px;
  left: 253.5px;
}
.subject {
  margin: 30px auto;
}
.subject label {
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  font-size: 30px;
  text-align: center;
  color: #000;
}
#charcontain {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  max-height: 724px;
  font-size: 0;
  white-space: nowrap;
  word-break: normal;
}
.wrapper .char {
  width: 20%;
  /* display: inline-block; */
  vertical-align: top;
  overflow: hidden;
  position: relative;
  float: left;
}
.wrapper .char .title {
  position: absolute;
  top: 110px;
  left: 60px;
  color: #000;
  font-size: 20px;
}
.subject .wrapper .char span {
  background-size: 100%;
  display: inline-block;
}
.wrapper .char p {
  width: 331px;
  font-size: 18px;
  color: #fff;
  white-space: pre-line;
  position: absolute;
  top: 277px;
  left: 287px;
  line-height: 20px;
  left: 416.281px;
  top: 317.167px;
  width: 222.017px;
  font-size: 15.8583px;
}
.allImgWidth {
  width: 812.74px;
  height: 614.51px;
}
.allClar:nth-child(1) .allImgWidth {
  background-image: url("../../assets/imgs/jiading/about_42.png");
}
.allClar:nth-child(2) .allImgWidth {
  background-image: url("../../assets/imgs/jiading/about_43.png");
}
.allClar:nth-child(3) .allImgWidth {
  background-image: url("../../assets/imgs/jiading/about_44.png");
}
.allClar:nth-child(4) .allImgWidth {
  background-image: url("../../assets/imgs/jiading/about_45.png");
}
.allClar:nth-child(5) .allImgWidth {
  background-image: url("../../assets/imgs/jiading/about_46.png");
}
.allClar {
  width: 380.6px;
  overflow: hidden !important;
}
.tutor {
  margin: 50px auto;
  width: 1200px;
}
.tutor label {
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  font-size: 30px;
  text-align: center;
  color: #000;
}
.tutor p {
  text-indent: 2em;
  font-size: 18px;
  color: #919191;
  line-height: 30px;
  color: #5a5a5a;
}
.vision label {
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  font-size: 30px;
  text-align: center;
  color: #000;
}
.vision p {
  text-indent: 2em;
  font-size: 18px;
  color: #5a5a5a;
  line-height: 30px;
  color: #5a5a5a;
}
.vision .img {
  text-align: center;
}
.vision .img img {
  margin-right: 50px;
}
.vision .img img {
  margin: 10px 0;
  margin-right: 50px;
  width: 26%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contx-box[data-v-9c6c46d2] {
    width: 90% !important;
  }
  .img img[data-v-9c6c46d2] {
    width: 31%;
    margin-right: 2%;
  }
  .img img[data-v-9c6c46d2]:last-child {
    margin-right: 0 !important;
  }
  .layout[data-v-9c6c46d2] {
    width: 90%;
  }
  #charcontain[data-v-9c6c46d2] {
    width: 100% !important;
  }
  .about_cont[data-v-9c6c46d2] {
    overflow: hidden;
  }
}
@media screen and (min-width: 320px) and (max-width: 750px) {
  .contx-box[data-v-9c6c46d2] {
    width: auto;
  }
  .contx-box > p[data-v-9c6c46d2] {
    padding: 15px;
    font-size: 14px;
    line-height: 24px;
  }
  .list[data-v-9c6c46d2] {
    margin: 15px;
  }
  .list li[data-v-9c6c46d2] {
    font-size: 14px;
  }
  .img {
    width: calc(100% - 30px);
    padding: 15px;
  }
  .img img[data-v-9c6c46d2] {
    margin-right: 0;
    width: 100%;
  }
  .layout[data-v-9c6c46d2] {
    width: auto;
  }
  .information label[data-v-9c6c46d2],
  .subject label[data-v-9c6c46d2],
  .tutor label[data-v-9c6c46d2],
  .vision label[data-v-9c6c46d2] {
    font-size: 22px;
  }
  .tutor p[data-v-9c6c46d2],
  .vision p[data-v-9c6c46d2] {
    font-size: 14px;
    padding: 0 15px;
    line-height: 24px;
  }
  .about_us {
    overflow: hidden;
  }
}
</style>